<template>
    
    <div v-if="showFinalPage" class="div50">
        <div class="RatingsDiv_left">
            <h6>{{DisclaimerStatment}}</h6>
        </div>

        <button class="btn" v-on:click="openReportIssue()">Report An Issue</button>         

        <button class = "ModalCloseBtn" v-on:click="closeFinalPage()">X</button>
        <h1>NBIS RATINGS</h1>
        <div class="RatingsDiv_center">
            <h2>{{ratingNum}}</h2>
        </div>
        <p>{{ratingText}}</p>
        <button class="btn_accent" v-on:click="openAbout()">IDOT Database</button>

        <br/>
        
        <div class="RatingsDiv_left">
            <h4>{{ElementSelected}}</h4>
            <p>{{ WorstConditionSelected }}</p>
        </div>
    </div>

    <div v-if="showReportIssue">
        <button class = "ModalCloseBtn" v-on:click="closeReportIssue()">X</button>
        <h1>Report an Issue</h1>
        <div class = "div50">
            <p>Fill out the items below before submitting to help set up an email to be sent to us about the issue you've found. Please be as specific as possible before submitting the email.</p>
        </div>
        
        <input placeholder="Name (required)" required v-model="nameForm">
        <br/>
        <input placeholder="Email (required)" type="email" required v-model="emailForm">
        <br/>
        <textarea placeholder="Issue Reported (required)" required v-model="issueForm"></textarea>
        <br/>
        <div class="div50">
            <button class="btn_full" v-on:click="submitIssueForm()">Submit Issue</button>
            <p class="errorFont" v-if="openErrorStatement">Error found, please fix the {{errorFormField}} field.</p>
        </div>
    </div>

    <div v-if="showAbout" class="div50">
        <button class = "ModalCloseBtn" v-on:click="closeAbout()">X</button>
        <h1>ABOUT NBIS RATINGS</h1>
        <h2>HOW IT'S MADE</h2>
        <p>{{HowItsMadeStatement}}</p>
        <button class="btn" v-on:click="openWebsite('https://idot.illinois.gov/Assets/uploads/files/Transportation-System/Manuals-Guides-&-Handbooks/Highways/Illinois%20Highway%20Information%20System%20Structure%20Info%20-%20Procedure%20Manual.pdf')">IDOT Structure Services Manual Link</button>
        <br/>
        <h2>ABOUT DEVELOPER</h2>
        <p>Cercle Designs, LLC has developed multiple bridge related apps including Bridge Inspect and Bridge Info</p>
        <button class="btn_accent" v-on:click="openWebsite('https://www.cercledesigns.com')">Cercle Studios</button>
        <button class="btn" v-on:click="openWebsite('https://cercledesigns.com/solutions/')">More Apps</button>
    </div>

    <div class="backdrop" v-if="showHomePage"> 

        <div class="TitleDiv2">
            <h6>{{DisclaimerStatment}}</h6>
        </div>

        <div class="TitleDiv">
            <h2>NBIS Ratings</h2>
            <button class="menu_btn" v-on:click="openAbout()">About</button>
            <button class="menu_btn" v-on:click="openWebsite('https://cercledesigns.com/solutions/')">More Apps</button>
            <button class="menu_btn" v-on:click="openReportIssue()">Report An Issue</button>
            <button class="btn_accent" v-on:click="openAbout()">IDOT Database</button>
        </div>

        <div class="MainDiv">
            <button class="btn" v-if="ChangeElementBtnShw" v-on:click="changeElement()">Change Element Selection</button>
            <div v-if="!ChangeElementBtnShw">
                <div>
                    <h3>Step1: Choose Element</h3>
                    <p>Choose the element you want to rate</p>
                    <h4>58 - Deck</h4>
                    <ul>
                        <li class="li_AllList" v-for="deckElement in DeckStructures" :key="deckElement.id" @click="nextStep1(deckElement.id)">{{deckElement.title}}</li>
                    </ul>
                    <h4>59 - Superstructure</h4>
                    <ul>
                        <li class="li_AllList" v-for="superElement in SuperStructures" :key="superElement.id" @click="nextStep2(superElement.id)">{{superElement.title}}</li>
                    </ul>

                    <h4>60 - Substructure</h4>
                    <ul>
                        <li class="li_AllList" v-for="subElement in SubStructures" :key="subElement.id" @click="nextStep3(subElement.id)">{{subElement.title}}</li>
                    </ul>

                    <h4>Additional Items</h4>
                    <ul>
                        <li class="li_AllList" v-for="addedElement in AddedStructures" :key="addedElement.id" @click="nextStep4(addedElement.id)">{{addedElement.title}}</li>
                    </ul>
                    
                </div>
            </div>
            
            <div v-if="ChangeElementBtnShw">
                <div>
                    <h3>Step2: Choose Condition</h3>
                    <h4>{{ElementSelected}}</h4>
                    <p>Choose the worst condition you detected in the field</p>
                    <ul>
                        <li class="li_AllList" v-for="(condition,index) in TempElements" :key="condition.index" @click="getRating(index)">{{ condition.title }}</li>
                    </ul>
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import { templateElement } from '@babel/types';

export default {
    name: "home",
    methods:{
        openWebsite(url){
            window.open(url,"_blank");
        },
        closeReportIssue(){
            this.showReportIssue = false,
            this.showHomePage = true
        },
        openReportIssue(){
            this.showFinalPage = false,
            this.showHomePage = false,
            this.showReportIssue = true
        },
        closeAbout(){
            this.showAbout = false,
            this.showHomePage = true
        },  
        openAbout(){
            this.showFinalPage = false,
            this.showHomePage = false,
            this.showAbout = true
        },
        closeAboutOpenMore(){
            this.showAbout = false,
            this.showMoreApps = true
        },
        closeMoreApps(){
            this.showMoreApps = false,
            this.showHomePage = true
        },
        closeFinalPage(){
            this.showFinalPage = false,
            this.showHomePage = true
        },
        openMoreApps(){
            this.showMoreApps = true,
            this.showHomePage = false
        },
        submitIssueForm(){
            //check if any items are empty
            this.mailtoBody = "Name: " + this.nameForm + " Email: " + this.emailForm + " Issue: " + this.issueForm
            this.mailtoLink = "mailto:info@cercledesigns.com?subject=NBIS Ratings Issue Reported&body=" + this.mailtoBody
            open(this.mailtoLink,"_blank");
        },
        getRating(listNum2){

            this.ratingNum = this.TempElements[listNum2].rating
            this.WorstConditionSelected = this.TempElements[listNum2].title
            
            if (this.ratingNum==9){
                this.ratingText = "New Construction"
            } else if (this.atingNum == 8){
                this.ratingText = "Very Good"
            } else if (this.ratingNum == 7) {
                this.ratingText = "Good"
            } else if (this.ratingNum == 6) {
                this.ratingText = "Satisfactory"
            } else if (this.ratingNum == 5) {
                this.ratingText = "Fair"
            } else if (this.ratingNum == 4) {
                this.ratingText = "Poor"
            } else if (this.ratingNum == 3) {
                this.ratingText = "Serious"
            } else if (this.ratingNum == 2) {
                this.ratingText = "Critical"
            } else {
                this.ratingText = "N/A"
            }
            
            this.showHomePage = false
            this.showFinalPage = true
        },
        changeElement(){
            this.ChangeElementBtnShw = false
        },
        nextStep1(listNum){
            if (listNum == 1) {
                this.TempElements = this.Conc_Deck
            } else if (listNum == 2) {
                this.TempElements = this.Steel_Deck
            } else {
                this.TempElements = this.Timber_Deck
            }
    
            this.ElementSelected = this.DeckStructures[listNum-1].title
            this.MainElementSelected = this.DeckStructures[listNum-1].mainTitle
            this.ChangeElementBtnShw = true
        },
        nextStep2(listNum){
            if (listNum == 1) {
                this.TempElements = this.Steel_Super
            } else if (listNum == 2) {
                this.TempElements = this.RC_Super
            } else if (listNum == 3){
                this.TempElements = this.DeckBeams_Super
            } else if (listNum == 4){
                this.TempElements = this.PPCI_Super
            } else if (listNum == 5){
                this.TempElements = this.Timber_Super
            }
            this.ElementSelected = this.SuperStructures[listNum-1].title
            this.MainElementSelected = this.SuperStructures[listNum-1].mainTitle
            this.ChangeElementBtnShw = true
        },
        nextStep3(listNum){
            if (listNum == 1) {
                this.TempElements = this.Conc_Sub
            } else if (listNum == 2) {
                this.TempElements = this.Steel_Sub
            } else if (listNum == 3) {
                this.TempElements = this.Timber_Sub
            }
            this.ElementSelected = this.SubStructures[listNum-1].title
            this.MainElementSelected = this.SubStructures[listNum-1].mainTitle
            this.ChangeElementBtnShw = true
        },
        nextStep4(listNum){
            if (listNum == 1) {
                this.TempElements = this.Channel_Add
            } else if (listNum == 2) {
                this,this.TempElements = this.Culvert_Add
            }
            this.ElementSelected = this.AddedStructures[listNum-1].title
            this.MainElementSelected = this.AddedStructures[listNum-1].mainTitle
            this.ChangeElementBtnShw = true
        }
    },
    data(){
        return{
            ChangeElementBtnShw: false,
            showFinalPage: false,
            showHomePage: true,
            showReportIssue: false,
            showAbout: false,
            showMoreApps: false,
            openErrorStatement: false,
            nameForm:'',
            emailForm:'',
            issueForm:'',
            mailtoBody:'',
            mailtoLink:'',
            errorFormField: '',
            ratingNum:5,
            ratingText:'Fair',
            ElementSelected: '61 - Channel',
            MainElementSelected: '58 - DECK',
            WorstConditionSelected:'Channel beams spalled or delaminated up to 30% section loss of the beam concrete cross section around the bottom primary reinforcement steel but not within 4ft of beam ends',
            DisclaimerStatment: "Disclaimer: Ratings provided herein are based on the standard indicated in the website via the red button. It is the Inspector's responsibility to choose the correct rating based on the site conditions.",
            HowItsMadeStatement:"NBIS Ratings allows users to find the ratings of any structural element based on the worst condition selected and noted in the field. The app was started by a Structures Engineer who performed a lot of bridge inspections within Illinois State and decided that it's time to develop an online version of the inspection manual. Currently the app is developed using the IDOT (Illinois Department of Transportation) database in their Structure Information and Procedure Manual.",
            TempElements:[],
            DeckStructures:[
                {id: 1, title: 'Concrete Bridge Deck', mainTitle: "58 - DECK" },
                {id: 2, title: 'Steel Bridge Deck', mainTitle: "58 - DECK"},
                {id: 3, title: 'Timber Bridge Deck', mainTitle: "58 - DECK"}
            ],
            SuperStructures:[
                {id: 1, title: 'Steel Superstructure', mainTitle: "59 - SUPERSTRUCTURE"},
                {id: 2, title: 'CIP & Precast Reinforced Concrete Superstructure', mainTitle: "59 - SUPERSTRUCTURE"},
                {id: 3, title: 'PPC Deck Beams', mainTitle: "59 - SUPERSTRUCTURE"},
                {id: 4, title: 'PPC I-Beams', mainTitle: "59 - SUPERSTRUCTURE"},
                {id: 5, title: 'Timber Superstructure', mainTitle: "59 - SUPERSTRUCTURE"}
            ],
            SubStructures:[
                {id: 1, title: 'Concrete or Masonry Substructure', mainTitle: "60 - SUBSTRUCTURE"},
                {id: 2, title: 'Steel Substructure', mainTitle: "60 - SUBSTRUCTURE"},
                {id: 3, title: 'Timber Substructure', mainTitle: "60 - SUBSTRUCTURE"}    
        ],
            AddedStructures:[
                {id: 1, title: '61 - Channel', mainTitle: "ADDITIONAL ITEMS"},
                {id: 2, title: '62 - Culvert', mainTitle: "ADDITIONAL ITEMS"}
            ],
            Channel_Add:[
                {title: 'There are no noteworthy deficiencies that affect the condition of the channel.', rating:9},
                {title: 'Banks are protected or well vegetated.', rating:8},
                {title: 'River control devices such as spur dikes and embankment protection are not required or are in a stable condition.', rating:8},
                {title: 'Bank protection is in need of minor repairs."', rating:7},
                {title: 'River control devices and embankment protection have a little minor damage.', rating: 7},
                {title: 'Banks and/or channel may have minor amounts of drift not affecting the waterway opening.', rating:7},
                {title: 'Bank is beginning to slump.', rating: 6},
                {title: 'River control devices and embankment protection have widespread minor damage.', rating: 6},
                {title: 'There is minor streambed movement evident. Debris is restricting the waterway slightly.', rating: 6},
                {title: 'Bank protection is being eroded.', rating: 5},
                {title: 'River control devices and/or embankment have major damage.', rating: 5},
                {title: 'Trees and brush restrict the channel.', rating: 5},
                {title: 'Bank and embankment protection is severely undermined.', rating: 4},
                {title: 'River control devices have severe damage.', rating: 4},
                {title: 'Deposits of debris in the waterways are severely restricting the opening.', rating: 4},
                {title: 'Bank protection has failed.', rating: 3},
                {title: 'River control devices have been destroyed.', rating: 3},
                {title: 'Streambed aggradation, degradation or lateral movement has changed the waterway to now threaten the bridge and/or approach roadway.', rating: 3},
                {title: 'The waterway has changed to the extent the bridge is near a state of collapse.', rating: 2},
            ],
            Culvert_Add:[
                {title: "New with no deficiencies", rating: 9},
                {title: "No noticeable or noteworthy deficiencies which affect the condition of the culvert, insignificant scrape marks caused by drift.", rating: 8},
                {title: "Isolated non-structural cracks up to .03”, light scaling, and insignificant spalling which does not expose reinforcing steel.", rating: 7},
                {title: "Metal culverts have a smooth symmetrical curvature with superficial corrosion and no pitting.", rating: 7},
                {title: "Insignificant damage caused by drift with no misalignment and not requiring corrective action.", rating: 7},
                {title: "Some minor scour has occurred near curtain walls, wingwalls, or pipes.", rating: 7},
                {title: "Extensive non-structural cracks up to .06” with some leaching over the majority of the top slab.", rating: 6},
                {title: "Spalls and delaminations may be present on up to 10% in a 6ft width of the concrete or masonry walls or slabs exposing primary reinforcement with surface rust only.", rating: 6},
                {title: "Up to 20% of the surface area of walls and slabs may be map cracked, spalled and delaminated.", rating: 6},
                {title: "Metal culverts have a smooth curvature, non-symmetrical shape, minor corrosion or measurable pitting.", rating: 6},
                {title: "Local minor scour at curtain walls, wingwalls, or pipes.", rating: 6},
                {title: "Non-structural cracking with leaching at < 5ft intervals over the majority of the slab or wall surfaces.", rating: 5},
                {title: "Structural cracks < 0.03” in walls or slabs.", rating: 5},
                {title: "Section loss of primary reinforcement up to 10% in the top slab in a 6ft width.", rating: 5},
                {title: "Up to 10% of compression surface area spalled or delaminated on top slabs in a 6ft width (tension areas may be totally spalled).", rating: 5},
                {title: "Up to 10% section loss of concrete or rebar in a 10ft width of wall.", rating: 5},
                {title: "Up to 10% section loss of concrete or reinforcement steel in a 10ft width of bottom slab.", rating: 5},
                {title: "Metal culverts have significant distorsion and deflection in no more than one section, or significant corrosion or deep pitting with up to 10% average section loss in a 10ft width.", rating: 5},
                {title: "Minor settlement or misalignment, noticeable scour or erosion at curtain walls, wingwalls, or pipes without undermining.", rating: 5},
                {title: "Structural cracks in top slab up to 0.06in.", rating: 4},
                {title: "Structural cracks in walls up to 0.125in.", rating: 4},
                {title: "Section loss of primary reinforcement up to 30% in the top slab in a 6ft width.", rating: 4},
                {title: "Up to 30% of compression surface area spalled or delaminated on top slabs in a 6ft width (tension areas may be totally spalled).", rating: 4},
                {title: "Up to 30% section loss of concrete or rebar in a 10ft width of wall.", rating: 4},
                {title: "Up to 30% section loss of concrete or reinforcement steel in a 10ft width of bottom slab.", rating: 4},
                {title: "Metal culverts have significant distortion and deflection on more than one section.", rating: 4},
                {title: "Extensive corrosion or deep pitting throughout with up to 30% section loss in a 10ft width.", rating: 4},
                {title: "Considerable settlement or misalignment, considerable scour or erosion at curtain walls, wingwalls or pipes with undermining.", rating: 4},
                {title: "Any combination of rating 4, up to 50% loss", rating: 3},
                {title: "Metal culverts have extreme distortion and deflection in one section (collapse)", rating: 3},
                {title: "Etensive corrosion, or deep pitting with scattered perforations", rating: 3},
                {title: "Severe movement or differential settlement of the segments, or loss of fill.", rating: 3},
                {title: "Holes may exist in walls or slabs. Integral wingwalls nearly severed from culvert.", rating: 3},
                {title: "Severe undermining of curtain walls, wingwalls or pipes.", rating: 3},
                {title: "Large areas of slab or walls spalled full depth near traffic, large area of reinforcement losses greater than 50% near traffic.", rating: 2},
                {title: "Metal culverts have extreme distortion and deflection throughout with extensive perforations due to corrosion.", rating: 2},
                {title: "Integral wingwalls collapsed.", rating: 2},
                {title: "Severe settlement of roadway due to loss of fill.", rating: 2},
                {title: "Section of culvert may have failed and can no longer support embankment.", rating: 2},
                {title: "Complete undermining of curtain walls and pipes", rating: 2},
                {title: "Special feature inspection will be required to keep the structure open with possible load restrictions.", rating: 2}
            ],
            Timber_Sub:[
                {title: "New Construction", rating: 9},
                {title: "No significant defects, insignificant damage caused by drift or collision, scour is insignificant.", rating: 8},
                {title: "Insignificant decay, cracking or splitting of timber, minor scour may have occurred.", rating: 7},
                {title: "Surface decay, cracking, splitting of timber.", rating: 6},
                {title: "Fire damage limited to surface scorching of timber with up to 2% section loss.", rating: 6},
                {title: "Shallow, local scour may have occurred near foundations. No exposed piles.", rating: 6},
                {title: "Minor decay, cracking or splitting of timber.", rating: 5},
                {title: "A few secondary members may need replacement but primary members are performing their function as designed with section loss up to 10%.", rating: 5},
                {title: "Fire damage limited to surface charring of timber with minor section loss up to 10%.", rating: 5},
                {title: "Spread footings exposed with no undermining on soil and up to 5% undermining on rock.", rating: 5},
                {title: "Less than 2ft of piles or seal coat exposed below pile supported footings.", rating: 5},
                {title: "Less than 6ft deep scour around pile bents with pile caps installed above the ground, no misalignment or settlement noted.", rating: 5},
                {title: "Serious decay, cracking, splitting or crushing of primary timber with section loss up to 30%.", rating: 4},
                {title: "Fire damage with section loss up to 30% that has reduced the load carrying capacity of the substructure", rating: 4},
                {title: "Exposure of timber piles greater than 2ft as a result of erosion.", rating: 4},
                {title: "Reducing the penetration, undermining of spread footing which may be affecting the stability of the unit but no significant settlement has yet occurred.", rating: 4},
                {title: "Section losses up to 50%, severe scour or undermining of footings affecting the stability of the unit with some settlement of the substructure.", rating: 3},
                {title: "Section loss greater than 50%, special feature inspection is required to allow bridge to remain open, measurable lateral or vertical movement, unstable structures", rating: 2}
            ],
            Steel_Sub:[
                {title: "New Construction.", rating: 9},
                {title: "No significant defects", rating: 8},
                {title: "Very minor damage caused by drift or collision with no misalignment.", rating: 8},
                {title: "Some light surface rust, minor scour may have occurred.", rating: 7},
                {title: "Up to 2% loss of steel section due to rust pitting may have occurred, but no effect on structural integrity of the substructure unit.", rating: 6},
                {title: "Shallow, local scour may have occurred at foundation with exposure of top of pile caps. No exposed piles.", rating: 6},
                {title: "Corrosion has caused moderate section loss up to 10% but overall ability of substructure to support the structure is unaffected.", rating: 5},
                {title: "Cracks may be present in non-critical areas.", rating: 5},
                {title: "Fatigue cracks in primary members have been arrested.", rating: 5},
                {title: "Spread footings exposed with no undermining on soil and up to 5% undermining on rock.", rating: 5},
                {title: "Less than 2ft of piles or seal coat exposed below pile supported footings.", rating: 5},
                {title: "Less than 6ft deep scour around piles with pile caps installed above the ground.", rating: 5},
                {title: "No misalignment or settlement noted.", rating: 5},
                {title: "Section loss up to 30% in critical areas of main steel members.", rating: 4},
                {title: "Localized buckling or cracks may be present in critical areas of primary members.", rating: 4},
                {title: "Undermining of spread footing which may be affecting the stability of the unit but no significant settlement has yet occurred.", rating: 4},
                {title: "Section losses up to 50%, severe scour or undermining of footings affecting the stability of the unit with some settlement of the substructure.", rating: 3},
                {title: "Section loss greater than 50%, special feature inspection is required to allow bridge to remain open.", rating: 2},
                {title: "Measurable lateral or vertical movement, unstable structures.", rating: 2}
            ],
            Conc_Sub:[
                {title: "New Construction.", rating: 9},
                {title: "No significant defects.", rating: 8},
                {title: "Shrinkage cracks, very light surface scaling, spalling or pop-outs which do not expose reinforcing steel.", rating: 8},
                {title: "Insignificant damage caused by drift or collision with no misalignment and no corrective action warranted.", rating: 8},
                {title: "Minor cracking, spalls or scaling with few incidences of exposed reinforcement with only surface rust.", rating: 7},
                {title: "Minor scour may have occurred at the foundation.", rating: 7},
                {title: "Moderate deterioration or disintegration, spalls, cracking and leaching on concrete or masonry units with up to 2% section loss or loss of bearing area.", rating: 6},
                {title: "Shallow, local scour may have occurred near foundations with exposure of top of pile supported footings, less than 2ft deep scour around pile bents. No exposed piles.", rating: 6},
                {title: "Large portions of concrete or masonry units are spalled, scaled, or delaminated with exposed reinforcing steel up to 10% loss of concrete (horizontal cross section).", rating: 5},
                {title: "Up to 10% loss of reinforcement steel", rating: 5},
                {title: "Extensive map cracking with leaching.", rating: 5},
                {title: "Spread footings with no undermining on soil and up to 5% undermining on rock.", rating: 5},
                {title: "Less than 2ft of exposed piles or seal coat below pile supported footings.", rating: 5},
                {title: "Less than 6ft deep scour around pile bents", rating: 5},
                {title: "Up to 10% section loss of bearing seats or piles.", rating: 5},
                {title: "Active cracks in concrete and masonry units that indicate a reduction in the substructure unit capacity to support the superstructure loads.", rating: 4},
                {title: "Up to 30% section loss of bearing seat(s) or pile(s).", rating: 4},
                {title: "Section loss of primary steel reinforcement up to 30%.", rating: 4},
                {title: "Section loss of concrete up to 30%.", rating: 4},
                {title: "Undermining of spread footing which may be affecting the stability of the unit but no significant settlement has yet occurred.", rating: 4},
                {title: "Section losses up to 50%, loss of bearing seat area to cause more than 2” drop.", rating: 3},
                {title: "Adjacent column ties are broken causing the vertical reinforcement to be ineffective.", rating: 3},
                {title: "Severe scour or undermining of footings affecting the stability of the unit with some settlement of the substructure.", rating: 3},
                {title: "Section loss greater than 50%, special feature inspection is required to allow bridge to remain open.", rating: 2},
                {title: "Measurable lateral or vertical movement.", rating: 2},
                {title: "Unstable structures.", rating: 2}
            ],
            Timber_Super:[
                {title: "New Construction.", rating: 9},
                {title: "May have only very minor defects in beams or stringers at non-critical locations.", rating: 8},
                {title: "Minor insignificant decay, cracking, or splitting of beams or stringers.", rating: 7},
                {title: "Some decay, cracking, or splitting of beams or stringers may be occurring near the main load carrying portions.", rating: 6},
                {title: "Fire damage limited to surface scorching with no significant section loss.", rating: 6},
                {title: "Moderate decay up to 10%, cracking, or splitting of beams or stringers but no significant effect in critical areas such as beam ends and mid-span.", rating: 5},
                {title: "Fire damage limited to surface charring with minor section loss up to 10%.", rating: 5},
                {title: "Extensive decay, cracking, splitting or crushing of beams or stringers, or fire damage with main load carrying portions affected.", rating: 4},
                {title: "Section loss up to 30%.", rating: 4},
                {title: "Severe decay, cracking, splitting or crushing of beams or stringers, or fire damage with major section loss up to 50% in critical load carrying portions of members.", rating: 3},
                {title: "Beam ends may be crushed or split with settlement of deck.", rating: 2},
                {title: "Section loss over 50%, special feature inspection is required to allow bridge to remain open.", rating: 2}
            ],
            PPCI_Super:[
                {title: "New Construction.", rating: 9},
                {title: "No notable problems.", rating: 8},
                {title: "No beams with prestressing strands, stirrup reinforcement bars or wire mesh exposed.", rating: 7},
                {title: "Minor shrinkage or release cracks may be present.", rating: 7},
                {title: "Minor map cracking at drains with sound concrete.", rating: 7},
                {title: "Center half of span: No beams with prestressing strands, stirrup reinforcement exposed.", rating: 6},
                {title: "End quarters of span: No more than 2 strands or 3” of stirrup reinforcement bars exposed in the bottom of any beam.", rating: 6},
                {title: "Beam ends (up to 3ft): Prestressed strands or stirrup reinforcement bars exposed up to 50%, perimeter of the bottom flange of any beam.", rating: 6},
                {title: "Larger width of stirrups may be exposed due to inadequate concrete cover occurring during manufacturing (up to 0.5in cover).", rating: 6},
                {title: "Webs may be spalled with exposed stirrups and only surface rust.", rating: 6},
                {title: "Center half of span: Prestressed strands or stirrup reinforcement bars exposed for no more than 25% the perimeter of the bottom flange of any beam.", rating: 5},
                {title: "End quarters of span: Prestressed strands or stirrup reinforcement bars exposed for no more than third the perimeter of the bottom flange of any beam.", rating: 5},
                {title: "Beam ends (up to 3ft): Prestressed strands or stirrup reinforcement bars exposed from 50% to full perimeter of the bottom flange of any beam.", rating: 5},
                {title: "Larger areas of stirrup may be exposed due to inadequate concrete cover that occurs during manufacturing (up to 0.5in cover).", rating: 5},
                {title: "Webs may be spalled with exposed stirrups minor section loss.", rating: 5},
                {title: "Center half of span: Prestressed strands or stirrup reinforcement bars exposed for no more than 66% the perimeter of the bottom flange of any beam.", rating: 4},
                {title: "End quarters of span: Prestressed strands or stirrup reinforcement bars exposed up to full perimeter of the bottom flange of any beam. No strands are exposed inside the exterior perimeter of strands.", rating: 4},
                {title: "Beam ends (up to 3ft): Prestressed strands or stirrup reinforcement bars exposed full perimeter of the bottom flange of any beam with some strands exposed inside the exterior perimeter of strands.", rating: 4},
                {title: "Webs are spalled with exposed stirrups with up to 30% section loss at ends of beams.", rating: 4},
                {title: "Center half of span: Prestressed strands or stirrup reinforcement bars exposed up to full perimeter of the bottom flange of any beam. No strands are exposed inside the exterior perimeter of strands.", rating: 3},
                {title: "End quarters of span: Prestressing strands, stirrup reinforcement bars exposed for the full perimeter of the bottom flange of any beam with some strands exposed inside the exterior perimeter of strands.", rating: 3},
                {title: "Hairline transverse cracks in bottom of beams or hairline vertical/diagonal shear cracks in beam webs may be developing.", rating: 3},
                {title: "Structural elements that are judged to be in critical condition must receive special inspections in order for the structure to remain open to traffic.", rating: 2},
                {title: "Measurable shear or transverse cracks.", rating: 2}
            ],
            DeckBeams_Super:[
                {title: "New Construction", rating: 9},
                {title: "No notable problems.", rating: 8},
                {title: "No beams with prestressing strands, stirrup reinforcement bars or wire mesh exposed.", rating: 7},
                {title: "Moderate cracking and leakage may be present in keyways, but no differential movement occurring between deck beams.", rating: 7},
                {title: "Center half of span: No beams with prestressing strands, stirrup reinforcement or wire mesh bars exposed, no longitudinal cracking or spalling along the bottom of the beams.", rating: 6},
                {title: "End quarters of span: No more than 2 strands or 3” of stirrup reinforcement bars or 3” of wire mesh exposed in the bottom of any beam.", rating: 6},
                {title: "Larger widths of wire mesh may be exposed due to inadequate concrete cover occurring during manufacturing (up to 0.5in cover)", rating: 6},
                {title: "Keyway cracking may be evident with wide spread leakage, but beams are still fully acting together.", rating: 6},
                {title: "Center half of span: No more than 2 strands or 3in of stirrup reinforcement bars or 3” of wire mesh exposed in any beam, longitudinal cracking or spalling limited to one edge with no other defects exposing reinforcement, wire mesh or strands.", rating: 5},
                {title: "End quarters of span: No more than 4 strands or 6in of stirrup reinforcement bars or 6in of wire mesh exposed in the bottom of any beam, no more than one longitudinal crack in any beam without any other defect.", rating: 5},
                {title: "Beam ends (up to 3ft): Prestressed strands, stirrup reinforcement bars or wire mesh exposed up to fullwidth of any beam bottom.", rating: 5},
                {title: "Larger widths of wire mesh may be exposed due to inadequate concrete cover occurring during manufacturing (up to 0.5in cover).", rating: 5},
                {title: "Keyway cracking with extensive leakage and evidence that beams are beginning to act independently of each other.", rating: 5},
                {title: "Center half of span: Prestressed strands, stirrup reinforcement bars or wire mesh exposed for no more than 1⁄3 the width of any beam bottom, spalling or delamination of the top of the beams down to the top reinforcement, one longitudinal crack in the bottom of any beam.", rating: 4},
                {title: "End quarters of span: Prestressed strands, stirrup reinforcement bars or wire mesh exposed for no more than 1⁄2 the width of any beam bottom, two longitudinal cracks in the bottom of any beam.", rating: 4},
                {title: "Beam ends (up to 3ft): Prestressed strands, stirrup reinforcement bars or wire mesh exposed up to full width of adjacent beam bottom with no exposed strands in the second layer of strands and sound concrete above the bottom layer.", rating: 4},
                {title: "Larger width of wire mesh exposed and actively corroding due to inadequate concrete cover occurring during manufacturing (up to 0.5in cover).", rating: 4},
                {title: "Keyway has failed with groups of beams acting independently of others.", rating: 4},
                {title: "Center half of span: Prestressing strands, stirrup reinforcement bars or wire mesh exposed for no more than half the width of any beam bottom, two longitudinal cracks in the bottom of any beam.", rating: 3},
                {title: "End quarters of span: Prestressing strands, stirrup reinforcement bars or wire mesh exposed for no more than 2⁄3 the width of any beam bottom.", rating: 3},
                {title: "Beam ends (up to 3ft): Prestressed strands, stirrup reinforcement bars or wire mesh exposed full width of adjacent beam bottom with exposed strands in the second layer of strands or unsound concrete above the bottom layer.", rating: 3},
                {title: "Keyways have failed causing a group of 3 or 4 beams to act independently from others.", rating: 3},
                {title: "Keyways have failed causing 1 or 2 beams to act independently from others.", rating: 2},
                {title: "Structural elements that are judged to be in critical condition must receive special inspections in order for the structure to remain open to traffic.", rating: 2}
            ],
            RC_Super:[
                {title: "New Construction", rating: 9},
                {title: "No significant defects", rating: 8},
                {title: "Very minor shrinkage cracks", rating: 8},
                {title: "Surface scaling", rating: 8},
                {title: "Spalling or pop-outs which do not expose reinforcing steel", rating: 8},
                {title: "Isolated non-structural cracks up to 0.03 inch", rating: 7},
                {title: "Minor pop-outs or spalls without exposed primary reinforcing steel", rating: 7},
                {title: "Stirrups may be exposed in a few locations", rating: 7},
                {title: "Extensive non-structural cracks up to 0.06 inch", rating: 6},
                {title: "Isolated hairline structural cracks", rating: 6},
                {title: "Spalls and delaminations may be present on up to 10% of a beams cross section or 6 ft width of a slab with exposed primary reinforcement with surface rust only", rating: 6},
                {title: "Up to 20% of a beam cross section or 6 ft width of a slab may be map cracked spalled and delaminated", rating: 6},
                {title: "Spalls and delaminations up to 5% on the sides of a beam crosssection", rating: 6},
                {title: "Non-structural cracks greater than 0.06 inch", rating: 5},
                {title: "Structural cracks up to 0.03 inch", rating: 5},
                {title: "Spalling with section loss of reinforcing steel up to 10% in a beam or 6 ft width of slab", rating: 5},
                {title: "Up to 10% of compression surface area spalled or delaminated in a beam cross section or 6ft width of slab", rating: 5},
                {title: "Up to 10% section loss of the concrete cross section", rating: 5},
                {title: "Flexural or shear cracks up to 0.06 inch", rating: 4},
                {title: "Primary reinforcing steel exposed with section loss up to 30% in a 6 ft width of slab or in a beam cross section", rating: 4},
                {title: "Up to 50% of the compression surface area spalled or delaminated", rating: 4},
                {title: "Channel beams spalled or delaminated up to 30% section loss of the beam concrete cross section around the bottom primary reinforcement steel but not within 4ft of beam ends", rating: 4},
                {title: "Primary reinforcing steel exposed with section loss up to 50% on a 6 ft width for slabs or cross section for beams", rating: 3},
                {title: "Up to 100% section loss of compression surface area in a 6 ft width of slab or beam cross section", rating: 3},
                {title: "Up to 50% section loss of the concrete cross section of a beam, channel beams spalled or delaminated around the bottom primary reinforcement steel within 4 ft of beam ends", rating: 3},
                {title: "Primary reinforcing steel exposed with section loss with with over 50% loss of reinforcing steel", rating: 2},
                {title: "Channel beams fully delaminated or spalled at ends with broken stirrups", rating: 2}
            ],
            Steel_Super:[
                {title: "New Construction", rating: 9},
                {title: "No Visible Rust", rating: 8},
                {title: "Some rust may be present but without any section loss", rating: 7},
                {title: "Initial section loss (minor pitting, scaling, or flaking) up to 2% section loss", rating: 6},
                {title: "Initial section loss up to 10% in critical areas", rating: 5},
                {title: "Fatigue or out-of-plane bending cracks may be present in secondary members", rating: 5},
                {title: "Arrested fatigue cracks may be present in primary members", rating: 5},
                {title: "Hinges may be showing minor corrosion problems", rating: 5},
                {title: "Anchor bolt(s) may be missing", rating: 5},
                {title: "Section loss up to 30% in critical area", rating: 4},
                {title: "Fatigue or out-of-plane bending cracks may be present in primary members", rating: 4},
                {title: "Previously arrested fatigue cracks propagating beyond arresting holes in primary members", rating: 4},
                {title: "Fatigue cracks in secondary members throughout the bridge", rating: 4},
                {title: "Anchor bolts or pintles broken on rocker bearings with an offset of 0.5 inch or more between the rocker and the bearing or sole plates", rating: 4},
                {title: "Advanced section loss up to 50%", rating: 3},
                {title: "Extensive perpendicular to stress fatigue or out of plane bending cracks in primary members", rating: 3},
                {title: "Severe section loss over 50% requires special inspections", rating: 2},
                {title: "Temporary supports or repairs may be required to remain open to traffic", rating: 2}
            ],
            Timber_Deck:[
                {title: "New Construction.", rating: 9},
                {title: "No crushing, rotting, or splitting, tightly secured to floor system.", rating: 8},
                {title: "Minor cracking, checking or splitting with a few loose planks.", rating: 7},
                {title: "A minor number of rotted or crushed planks in need of replacement.", rating: 6},
                {title: "Many planks cracked or split.", rating: 6},
                {title: "Many loose planks.", rating: 6},
                {title: "Fire damage limited to surface scorching with insignificant section loss.", rating: 6},
                {title: "Some wet areas noted.", rating: 6},
                {title: "Numerous planks cracked, split, some non-adjacent planks rotted, or crushed and in need of replacement.", rating: 5},
                {title: "Many planks may be loose", rating: 5},
                {title: "Fire damage limited to surface charring with minor section loss.", rating: 4},
                {title: "Majority of the planks are rotted, crushed, and/or split.", rating: 4},
                {title: "Necessitating replacement of the entire deck.", rating: 4},
                {title: "Fire damage may be present, with >10% section loss to a significant area of the deck.", rating: 4},
                {title: "Severe signs of structural distress are visible to the point where vehicular loads may have to be restricted.", rating: 3},
                {title: "Major fire damage which will substantially reduce the sectional area of the plank.", rating: 3},
                {title: "Advanced deterioration with partial deck failure to the point where a special inspection at reduced intervals is necessary to allow the structure to remain open, possibly with reduced load limits", rating: 2}
            ],
            Steel_Deck:[
                {title: "New Construction", rating: 9},
                {title: "Tightly secured to floor system with no rust.", rating: 8},
                {title: "Sound connections with minor rusting, no cracked welds.", rating: 7},
                {title: "Considerable rusting with indications of initial section loss.", rating: 6},
                {title: "Sound connections with isolated cracked welds and/or isolated broken grids.", rating: 6},
                {title: "Heavy rusting with areas of up to 10% section loss in a 6 foot wide bay.", rating: 5},
                {title: "Isolated loose connections.", rating: 5},
                {title: "Numerous cracked welds and/or broken grids", rating: 5},
                {title: "Grid sections may be uplifting in isolated areas without danger of breaking loose.", rating: 5},
                {title: "Heavy rusting resulting in considerable section loss up to 30% in a 6 foot wide bay and numerous holes in grid or deck structural elements resulting in many welds cracked and/or grids broken", rating: 4},
                {title: "Uplifting of grid sections may be occurring throughout deck with danger of breaking loose.", rating: 4},
                {title: "Severe or critical signs of structural distress are visible to the point where vehicular loads may need to be restricted.", rating: 3},
                {title: "Sections have broken loose and are being repaired occasionally.", rating: 3},
                {title: "Same as previous condition but special inspections are required to allow bridge to remain open, possibly with reduced load limits.", rating: 2}
            ],
            Conc_Deck:[
                {title: "New Construction.", rating: 9},
                {title: "Transverse cracks < 0.06in at > 15ft intervals may be present but no spalling, scaling, pop-outs or delamination.", rating: 8},
                {title: "Some transverse cracks < 0.06in at > 5ft intervals over the majority of the deck", rating: 7},
                {title: "Light scaling (less than 0.25inch depth) or pop-outs may be present, no spalling.", rating: 7},
                {title: "Transverse cracks < 0.06in at < 5ft or > 0.06inch at > 5ft intervals over a majority of the deck", rating: 6},
                {title: "Isolated longitudinal cracks, spalls and delaminations may be present on up to 5% of the deck riding surface or soffit area.", rating: 6},
                {title: "Up to 10% of the deck soffit may be spalled, delaminated, and map cracked.", rating: 6},
                {title: "Transverse cracks > 0.06in at < 5ft intervals with or without leaching in the majority of the deck", rating: 5},
                {title: "Longitudinal cracks < 0.06in in majority of deck.", rating: 5},
                {title: "Spalls and delaminations may be present on up to 10% of the deck surface or soffit area.", rating: 5},
                {title: "Up to 25% of the deck surface or soffit may be spalled, delaminated and map cracked.", rating: 5},
                {title: "Up to 10% loss of primary reinforcement in any 6ft bay length.", rating: 5},
                {title: "Longitudinal cracks > 0.06in in majority of deck.", rating: 4},
                {title: "Spalls and delaminations may be present on up to 25% of the deck surface or soffit area.", rating: 4},
                {title: "Up to 50% of the deck surface or soffit may be spalled, delaminated and map cracked.", rating: 4},
                {title: "Up to 30% loss of primary reinforcement in any 6ft bay length.", rating: 4},
                {title: "Extensive full depth failures are evident to the point that wheel loads may need restricted or temporary measures implemented.", rating: 3},
                {title: "Full depth failures needing patching over much of the deck on a regular basis which requires special inspections to keep the bridge open, possibly with reduced load limits, temporary measures may be needed to allow continued use of the structure.", rating: 2}

            ]
        }
    }
}
</script>

<style>

    
</style>